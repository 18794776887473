import React from 'react';
import { Box, Grid } from '@mui/material';
import {
    //BlackButton,
    BookingLayout,
    FullWidthImage
} from 'components';
import { hero2, iconCheck } from 'assets/images';
import { EditBooking } from 'model/Booking';
import { MarketplaceState } from 'reducers/marketplace/MarketplaceState';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from 'utils';

interface BookingSuccessProps {
    booking: EditBooking;
    //
    onContinue: () => void;
}

export const BookingSuccess: React.FunctionComponent<BookingSuccessProps> = () => {
    const { marketplace } = useSelector<RootState, MarketplaceState>(state => state.marketplace);

    return (
        <BookingLayout
            sx={{
                '.BookingLayout-left-column': {
                    display: 'flex'
                }
            }}
            leftColumn={
                <Grid item xs={12}>
                    <Box
                        sx={{
                            'textAlign': 'center',
                            'maxWidth': { xs: '100%', md: 435 },
                            'mx': 'auto',
                            'px': { xs: 1, md: 0 },
                            'minHeight': { xs: '70vh', md: 'auto' },
                            'display': 'flex',
                            'flexDirection': 'column',
                            'justifyContent': 'center',
                            'h1': { mt: 0, mb: 0 },
                            'p b:last-child': { display: { xs: 'block', md: 'inline' } }
                        }}
                    >
                        <Box sx={{ img: { width: 64, mb: 1 } }}>
                            <img src={iconCheck} alt="Check icon" />
                        </Box>

                        <h1 id="bookingSuccess_label">
                            {marketplace.bookingRequestsEnabled
                                ? 'Appointment requested!'
                                : 'Appointment booked!'}
                        </h1>

                        {marketplace.bookingRequestsEnabled && (
                            <p>
                                Your appointment request is currently pending. We&apos;ll notify you
                                once it&apos;s accepted. If you have any questions contact{' '}
                                <b>{marketplace.name}</b> at{' '}
                                <b>
                                    {marketplace.phone ? formatPhoneNumber(marketplace.phone) : '-'}
                                </b>
                            </p>
                        )}

                        {!marketplace.bookingRequestsEnabled && (
                            <p>
                                Check your inbox for an appointment confirmation. If you have any
                                questions contact <b>{marketplace.name}</b> at{' '}
                                <b>
                                    {marketplace.phone ? formatPhoneNumber(marketplace.phone) : '-'}
                                </b>
                            </p>
                        )}
                    </Box>
                </Grid>
            }
            rightColumn={<FullWidthImage src={hero2} />}
        />
    );
};

export default BookingSuccess;
